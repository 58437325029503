@import url(coach.scss);
@import url(course.scss);
@import url(responsive.scss);
@import url(enrol.scss);

.carousel {
  visibility: visible;
  background: black;
  padding: 0px;
  margin: 0px auto;
  height: 646px;
  display: block;
  width: auto;
  overflow: visible;
  transform: translate(0px, 0px);
  background-image: url("../uploads/landing-bg.jpg");

  .carousel-inner {
    height: 100%;
    .carousel-item {
      height: 100%;
    }
    .container {
      height: 100%;
    }
  }

  .row {
    height: 100%;
    width: 100%;

    .col:first-child,
    .col:nth-child(2) {
      position: relative;

      h3 {
        z-index: 5;
        touch-action: pan-y;
        height: auto;
        width: auto;
        color: rgb(255, 255, 255);
        text-decoration: none;
        white-space: nowrap;
        min-height: 0px;
        min-width: 0px;
        max-height: none;
        text-align: left;
        max-width: none;
        line-height: 46px;
        letter-spacing: 0px;
        font-weight: 700;
        font-size: 39px !important;
        backdrop-filter: none;
        filter: none;
        transform-origin: 50% 50%;
        opacity: 1;
        transform: perspective(600px);
        visibility: visible;
      }
      p {
        font-size: 25px;
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.4);
        margin: 35px 0;
        padding: 15px 20px;
        white-space: nowrap;
        text-align: left;
      }
    }

    .col:nth-child(2) {
      margin-top: 5%;
    }
  }

  .carousel-caption {
    margin: 0;
    position: absolute;
    top: 55%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  h1,
  h3,
  p {
    color: #fff;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.8);
    background-size: 100% 60%;
    width: 4rem;
    height: 4rem;
  }
}

.product-container {
  margin: 30px;
  height: auto;
  .products {
    height: 270px;

    .product-item {
      height: 100%;
      background: red;
      margin: 8px;
    }
  }
}

.intro {
  .intro-caption {
    h3 {
      font-size: 50px;
    }
  }
}

.courses {
}




//////////////////////////////////////////////
.elementor-widget-text-editor.elementor-drop-cap-view-stacked
  .elementor-drop-cap {
  background-color: #69727d;
  color: #fff;
}
.elementor-widget-text-editor.elementor-drop-cap-view-framed
  .elementor-drop-cap {
  color: #69727d;
  border: 3px solid;
  background-color: transparent;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
  .elementor-drop-cap {
  margin-top: 8px;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
  .elementor-drop-cap-letter {
  width: 1em;
  height: 1em;
}
.elementor-widget-text-editor .elementor-drop-cap {
  float: left;
  text-align: center;
  line-height: 1;
  font-size: 50px;
}
.elementor-widget-text-editor .elementor-drop-cap-letter {
  display: inline-block;
}

.elementor-widget-image {
  text-align: center;
}
.elementor-widget-image a {
  display: inline-block;
}
.elementor-widget-image a img[src$=".svg"] {
  width: 48px;
}
.elementor-widget-image img {
  vertical-align: middle;
  display: inline-block;
}

.elementor-widget-video .elementor-widget-container {
  overflow: hidden;
  transform: translateZ(0);
}
.elementor-widget-video .elementor-wrapper {
  aspect-ratio: var(--video-aspect-ratio);
}
.elementor-widget-video .elementor-wrapper iframe,
.elementor-widget-video .elementor-wrapper video {
  height: 100%;
  width: 100%;
  display: flex;
  border: none;
  background-color: #000;
}
@supports not (aspect-ratio: 1/1) {
  .elementor-widget-video .elementor-wrapper {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: calc(100% / var(--video-aspect-ratio));
  }
  .elementor-widget-video .elementor-wrapper iframe,
  .elementor-widget-video .elementor-wrapper video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.elementor-widget-video
  .elementor-open-inline
  .elementor-custom-embed-image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: 50%;
}
.elementor-widget-video .elementor-custom-embed-image-overlay {
  cursor: pointer;
  text-align: center;
}
.elementor-widget-video
  .elementor-custom-embed-image-overlay:hover
  .elementor-custom-embed-play
  i {
  opacity: 1;
}
.elementor-widget-video .elementor-custom-embed-image-overlay img {
  display: block;
  width: 100%;
  aspect-ratio: var(--video-aspect-ratio);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}
@supports not (aspect-ratio: 1/1) {
  .elementor-widget-video .elementor-custom-embed-image-overlay {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: calc(100% / var(--video-aspect-ratio));
  }
  .elementor-widget-video .elementor-custom-embed-image-overlay img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.elementor-widget-video .e-hosted-video .elementor-video {
  -o-object-fit: cover;
  object-fit: cover;
}
.e-con-inner > .elementor-widget-video,
.e-con > .elementor-widget-video {
  width: var(--container-widget-width);
  --flex-grow: var(--container-widget-flex-grow);
}

.elementor-widget-social-icons.elementor-grid-0 .elementor-widget-container,
.elementor-widget-social-icons.elementor-grid-mobile-0
  .elementor-widget-container,
.elementor-widget-social-icons.elementor-grid-tablet-0
  .elementor-widget-container {
  line-height: 1;
  font-size: 0;
}
.elementor-widget-social-icons:not(.elementor-grid-0):not(
    .elementor-grid-tablet-0
  ):not(.elementor-grid-mobile-0)
  .elementor-grid {
  display: inline-grid;
}
.elementor-widget-social-icons .elementor-grid {
  grid-column-gap: var(--grid-column-gap, 5px);
  grid-row-gap: var(--grid-row-gap, 5px);
  grid-template-columns: var(--grid-template-columns);
  justify-content: var(--justify-content, center);
  justify-items: var(--justify-content, center);
}
.elementor-icon.elementor-social-icon {
  font-size: var(--icon-size, 25px);
  line-height: var(--icon-size, 25px);
  width: calc(var(--icon-size, 25px) + 2 * var(--icon-padding, 0.5em));
  height: calc(var(--icon-size, 25px) + 2 * var(--icon-padding, 0.5em));
}
.elementor-social-icon {
  --e-social-icon-icon-color: #fff;
  display: inline-flex;
  background-color: #69727d;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.elementor-social-icon i {
  color: var(--e-social-icon-icon-color);
}
.elementor-social-icon svg {
  fill: var(--e-social-icon-icon-color);
}
.elementor-social-icon:last-child {
  margin: 0;
}
.elementor-social-icon:hover {
  opacity: 0.9;
  color: #fff;
}
.elementor-social-icon-android {
  background-color: #a4c639;
}
.elementor-social-icon-apple {
  background-color: #999;
}
.elementor-social-icon-behance {
  background-color: #1769ff;
}
.elementor-social-icon-bitbucket {
  background-color: #205081;
}
.elementor-social-icon-codepen {
  background-color: #000;
}
.elementor-social-icon-delicious {
  background-color: #39f;
}
.elementor-social-icon-deviantart {
  background-color: #05cc47;
}
.elementor-social-icon-digg {
  background-color: #005be2;
}
.elementor-social-icon-dribbble {
  background-color: #ea4c89;
}
.elementor-social-icon-elementor {
  background-color: #d30c5c;
}
.elementor-social-icon-envelope {
  background-color: #ea4335;
}
.elementor-social-icon-facebook,
.elementor-social-icon-facebook-f {
  background-color: #3b5998;
}
.elementor-social-icon-flickr {
  background-color: #0063dc;
}
.elementor-social-icon-foursquare {
  background-color: #2d5be3;
}
.elementor-social-icon-free-code-camp,
.elementor-social-icon-freecodecamp {
  background-color: #006400;
}
.elementor-social-icon-github {
  background-color: #333;
}
.elementor-social-icon-gitlab {
  background-color: #e24329;
}
.elementor-social-icon-globe {
  background-color: #69727d;
}
.elementor-social-icon-google-plus,
.elementor-social-icon-google-plus-g {
  background-color: #dd4b39;
}
.elementor-social-icon-houzz {
  background-color: #7ac142;
}
.elementor-social-icon-instagram {
  background-color: #262626;
}
.elementor-social-icon-jsfiddle {
  background-color: #487aa2;
}
.elementor-social-icon-link {
  background-color: #818a91;
}
.elementor-social-icon-linkedin,
.elementor-social-icon-linkedin-in {
  background-color: #0077b5;
}
.elementor-social-icon-medium {
  background-color: #00ab6b;
}
.elementor-social-icon-meetup {
  background-color: #ec1c40;
}
.elementor-social-icon-mixcloud {
  background-color: #273a4b;
}
.elementor-social-icon-odnoklassniki {
  background-color: #f4731c;
}
.elementor-social-icon-pinterest {
  background-color: #bd081c;
}
.elementor-social-icon-product-hunt {
  background-color: #da552f;
}
.elementor-social-icon-reddit {
  background-color: #ff4500;
}
.elementor-social-icon-rss {
  background-color: #f26522;
}
.elementor-social-icon-shopping-cart {
  background-color: #4caf50;
}
.elementor-social-icon-skype {
  background-color: #00aff0;
}
.elementor-social-icon-slideshare {
  background-color: #0077b5;
}
.elementor-social-icon-snapchat {
  background-color: #fffc00;
}
.elementor-social-icon-soundcloud {
  background-color: #f80;
}
.elementor-social-icon-spotify {
  background-color: #2ebd59;
}
.elementor-social-icon-stack-overflow {
  background-color: #fe7a15;
}
.elementor-social-icon-steam {
  background-color: #00adee;
}
.elementor-social-icon-stumbleupon {
  background-color: #eb4924;
}
.elementor-social-icon-telegram {
  background-color: #2ca5e0;
}
.elementor-social-icon-threads {
  background-color: #000;
}
.elementor-social-icon-thumb-tack {
  background-color: #1aa1d8;
}
.elementor-social-icon-tripadvisor {
  background-color: #589442;
}
.elementor-social-icon-tumblr {
  background-color: #35465c;
}
.elementor-social-icon-twitch {
  background-color: #6441a5;
}
.elementor-social-icon-twitter {
  background-color: #1da1f2;
}
.elementor-social-icon-viber {
  background-color: #665cac;
}
.elementor-social-icon-vimeo {
  background-color: #1ab7ea;
}
.elementor-social-icon-vk {
  background-color: #45668e;
}
.elementor-social-icon-weibo {
  background-color: #dd2430;
}
.elementor-social-icon-weixin {
  background-color: #31a918;
}
.elementor-social-icon-whatsapp {
  background-color: #25d366;
}
.elementor-social-icon-wordpress {
  background-color: #21759b;
}
.elementor-social-icon-x-twitter {
  background-color: #000;
}
.elementor-social-icon-xing {
  background-color: #026466;
}
.elementor-social-icon-yelp {
  background-color: #af0606;
}
.elementor-social-icon-youtube {
  background-color: #cd201f;
}
.elementor-social-icon-500px {
  background-color: #0099e5;
}
.elementor-shape-rounded .elementor-icon.elementor-social-icon {
  border-radius: 10%;
}
.elementor-shape-circle .elementor-icon.elementor-social-icon {
  border-radius: 50%;
}
