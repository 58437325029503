.top_banner {
  float: left;
  position: relative;
  width: 100%;
  z-index: 999;
  transform: translateY(0px);
  transition: all 0.2s linear 0s;
  padding: 10px 0;

  color: #fff;
  background: #0b5c59;
}

.navigation {
  color: #fff;
  width: 100%;
  background-color: #011929;
  padding: 25px !important;

  .brand {
    font-weight: 600;
  }

  a, .brand, span {
    color: #fff;
  }

}


.button{

    &:hover{

    }
}