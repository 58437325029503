.responsive-menu ul li a {
    font-display: swap;
  }
  .responsive-menu > ul > li.menu-item-has-children.active > a {
    color: #2088c7;
  }
  .header-area.style2 .top-search i,
  .header-area.style2 .top-shop i,
  .header-area.style2 .top-shop > span,
  .header-area.style2 .login-btn > a,
  .header-area.style2 .language > span {
    color: #ffffff;
  }
  .bottombar span {
    color: #fff;
  }
  .site-title {
    color: #000000;
  }
  .site-title {
    color: #000000;
  }
  .site-title {
    color: #000000;
  }
  .site-title {
    color: #000000;
  }


  #rev_slider_2_1_wrapper .custom.tparrows {
    cursor: pointer;
    background: #000;
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000;
  }
  #rev_slider_2_1_wrapper .custom.tparrows.rs-touchhover {
    background: #000;
  }
  #rev_slider_2_1_wrapper .custom.tparrows:before {
    font-family: "revicons";
    font-size: 15px;
    color: #fff;
    display: block;
    line-height: 40px;
    text-align: center;
  }
  #rev_slider_2_1_wrapper .custom.tparrows.tp-leftarrow:before {
    content: "\e824";
  }
  #rev_slider_2_1_wrapper .custom.tparrows.tp-rightarrow:before {
    content: "\e825";
  }