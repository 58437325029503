*{
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */

  position: relative;
  z-index: 999;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container {
  max-width: 1280px;
}
.container, .container-fluid {
  padding: 0;
}
@media (min-width: 1200px) {
  .container {
      max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .container {
      max-width: 960px;
  }
}
@media (min-width: 768px) {
  .container {
      max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container {
      max-width: 540px;
  }
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}




.main-heading > span,
.video-sec-meta > h1,
.blog-meta > h2:hover,
.blog-meta > ul li:hover,
.couch-info span,
.couch-info h4,
.package-detail > span,
.package-detail > ul li i,
.col-heading > span,
.lecture-meta > h4:hover,
.lecture-avatar:hover a i,
.video-lectures .seemore:hover,
.happy-client:before,
.happy-client:after,
.recent-info > span,
.service-links > li a:hover,
.recent-info h5 a:hover,
.twitter-meta > span,
.twitter-widget > li i,
.twitter-meta > h5:hover,
.coach-info h3:hover,
.funfact-info h2 span,
.why-coach > h5 a:hover,
.bread-crumb > li a:hover,
.main-menu > div > ul > li ul li a:hover,
aside .widget .twitter-meta > h5:hover,
.comment-meta > i,
.event-detail ul li span,
.event-info > h3:hover,
.team-info > h4:hover,
.overlay-meta > h4 a:hover,
.contact-us > h3 span,
.error-page > p a,
.search-area > h3 span,
.responsive-menu > ul > li.menu-item-has-children.active > a,
.responsive-menu ul ul li a:hover,
.mega-info .address > li i,
.video-meta > h4:hover,
.total-area > ul li.order-total,
.profiel-meta > span,
.nav.nav-tabs.coaching > li a.current,
.nav.nav-tabs.coaching > li a.active,
.about-coach > span,
.coach-detail > span,
.tags-area > ul li a:hover,
.cat-area > ul li a:hover,
.tags-area > span,
.cat-area > span,
.about-course > h4:hover,
.table-condensed tbody tr td.active,
.services-avatar > i,
.widget > ul li a:hover,
.woocommerce-info:before,
.woocommerce-info a,
.woocommerce-error a,
.woocommerce-message a,
.woocommerce-MyAccount-content a,
.woocommerce-account .woocommerce-MyAccount-navigation > ul li.is-active a,
.woocommerce-product-rating a:hover,
.posted_in a:hover,
.woocommerce-order > p,
.woocommerce-message::before,
.table-cart tbody tr td.buy-now,
.mega2-info .mega-meta > a:hover,
table tr td a, table tr th a,
nav.navigation.comment-navigation > div > a,
.single-meta.single-blockquote p > a,
.prod-list-info .prices > ins,
.tags > span,
.tags > a:hover,
.nav.single-btn > li a.active,
.comment_container .comment-text .post-date,
.comment-notes,
.single-meta > ol > li > a,
.logged-in-as a,
/*new*/
a.new-btn-brdr,
.promo-vid figure > a i,
.new-promo-funfact .funfact .counter,
.table-body > ul li i,
.banner-meta > span,
.banner-meta > h4 span,
.promo-vid figure > a:hover em,
.new-btn:hover,
.case-stdy > li > a.selected,
.sec-title > span,
.consult-service > i,
.header-area.style2 .main-menu .cart-dropdown i,
.res-logoarea .cart-dropdown i,
.promo-data form .form-group input[type="submit"]:hover,
.map-locato > li > span,
.map-locato a,
.feature-over-meta > h2 span,
.welcome-parallax .new-btn:hover,
.notes-info > h3 a:hover,
.location:hover,
.latest-event-meta > span,
.pkg-info .new-btn:hover,
.pkg-info > h2,
.guideline-package > a:hover,
.buyer-deta > span,
.update-newsmeta > ul li i,
.learning-gal-txt span,
.featured-course-content .f-course-duration,
.heading-style-5 span,
.review-text-box i,
footer.home5-style .bottom-menu ul li a:hover,
.header-area.style5 .login-btn > ul > li > a:hover,
ul.timing-info i,
.blog-meta > ul li i,
.page-loader .percentage-counter p
{
	color: #ffab17;	
}
/*=========================
	BACKGROUND COLOR
=========================*/
.branches > span,
.topbar,
.main-menu,
.top-strip::after,
.top-strip::before,
.consult-sec,
.panel-title > a[aria-expanded="true"],
.widget-title::before,
.subscriber form button,
.funfact > h4::before,
.topbar2,
.nice-select .list,
.top-shop > span,
.pagenation > li a:hover,
.pagenation > li.active a,
.pagenation > li span.current,
.check-buttons > a:hover,
.top-shop::before,
/*.main-menu > ul > li ul li.menu-item-has-children::before,*/
.reply-btn:hover,
.comment-reply-link:hover,
.event-date-box,
.prod-info,
.purchaser > a:hover,
.coming-meta form button,
.coming-soon-top .social-media li a:hover,
.login-area form button,
.footer-meta button,
.res-top-bar,
.mega-meta > span::before,
.checkout-bg.blue,
.edit-fields form button,
.del-account > a,
.profiel-meta > a,
.send-query > a,
.package .button,
.banner-info .button,
.question-popup form .button,
.header-area .appointment .button:hover,
.video-sec-meta .button:hover,
.funfact-info a.button:hover,
.contact-from .button,
.delete-cart:hover,
.error-page form button,
.twitter-widget .ps-scrollbar-y,
.product-scroller .ps-scrollbar-y,
.cart-scrollbar .ps-scrollbar-y,
.language > ul,
.branches > ul,
.searchform button:hover,
.appoint-form .chosen-container .chosen-drop,
.woocommerce #respond input#submit:hover, 
.woocommerce a.button:hover, 
.woocommerce button.button:hover, 
.woocommerce input.button:hover,
.wc-proceed-to-checkout.proceed a.button:hover,
.place-order input.button:hover,
.woocommerce ul.products li.product .added_to_cart:hover,
.woocommerce div.product form.cart .button:hover,
.del-account button:hover,
.del-account a:hover,
.about-course a.new-course,
.view-more > a:hover,
.post-password-form > p input[type="submit"],
.info > a:hover,
.mor-pix > ul li a::before,
.woocommerce span.onsale,
.promo-data form input[type="submit"],
.loader .line-scale > div,
.news-letter form button,
a.free-consult,
.feature-over-meta > a,
.feature-over-meta > span::before,
.welcome-gallery .owl-prev:hover:before, .welcome-gallery .owl-next:hover:before,
.twitter-caro .owl-prev:hover:before, .twitter-caro .owl-next:hover:before,
.link-course:hover,
.more-info > a,
.pkg-btn .nav-item > a.active,
.main-heading.style3::after,
.update-news:hover .update-newsmeta > a,
.option-set.new-course > li a.selected,
.learning-gallery .owl-dot::before,
.theme-border-btn:hover,
.featured-courses-tabs .nav-tabs li a.active::before,
.learning-service a,
.heading-style-5 .heading-anime::before,
.view-gal,
.header-area.style5 .login-btn > a:hover,
.header-area.style5 .top-search i:hover,
.language-header5 > span:hover,
.login-popup-style5 form button,
.register-popup-style5 form button,
.login-popup-style5 > span,
.register-popup-style5 > span,
.main-menu > ul > li ul li a:hover,
.main-menu > div > ul > li ul li a:hover,
.page-loader .percentage-counter hr
{
	background: #0b5c59;
}
.selection .list li:hover,
.owl-dot.active,
.place-order input.button:hover,
/*new*/
.new-btn,
.table-head > em,
.price-tbl:hover,
.price-tbl:hover .table-head,
.price-tbl.active,
.price-tbl.active .table-head,
.newsletter > button,
.new-btn-brdr:hover,
.feature-caro .owl-prev:hover::before, .feature-caro .owl-next:hover::before,
.latest-case::before,
.case-upper,
.latest-case .case-link:hover,
.promo-data form input[type="submit"]:hover,
.photo-img .popup-gall a::after,
.topbar,
.select-branche,
.select-language
 {
    background: #0b5c59 !important;
}
/*=========================
	BORDER COLOR
=========================*/
.purchaser > a,
.main-menu > ul > li ul,
.main-menu > div > ul > li ul, 
.quick-contact a.button,
.package .button,
.banner-info .button,
.funfact-info .button,
.pagenation > li a:hover,
.pagenation > li.active a,
.contact-from .button,
.appoint-form .button,
.subscribe-popup .button,
.responsive-menu > ul > li.menu-item-has-children.active > a,
.question-popup form .button,
.nav.nav-tabs.coaching > li a.active,
.tab-video-btn li.active > img,
.woocommerce-info,
.woocommerce .userincr-container input.userincr-btn-dec:hover,
.woocommerce .userincr-container input.userincr-btn-inc:hover,
.woocommerce .userincr-container input.userincr-btn-dec:focus,
.woocommerce .userincr-container input.userincr-btn-inc:focus,
.info > a:hover, 
/*new*/
a.new-btn-brdr,
.promo-vid figure > a:hover em,
.new-btn:hover,
button.new-btn:hover,
.promo-data form input[type="submit"],
.welcome-gallery .owl-prev::before, 
.welcome-gallery .owl-next::before,
.twitter-caro .owl-prev::before, 
.twitter-caro .owl-next::before,
.option-set.new-course > li a.selected,
.theme-border-btn:hover,
.learning-gallery .owl-dot.active
{
	border-color:#ffab17;	
}
.nav.nav-tabs.coaching > li a.active{
    border-bottom-color: #062f2b;
}
.woocommerce-error, .woocommerce-info, .woocommerce-message{
	border-top-color: #ffab17 !important;	
}
.update-cart-btn{margin-bottom: 20px;}
.featured-courses-tabs .nav-tabs li a.active::after {
	border-right-color:#ffab17;
}
/*
main color file
Project: esperto:
color: yellow
	
*/

/*=========================
	FONT COLOR
=========================*/

.video-prices,
.promo-package li i,
.feature-caro-btn .owl-prev:hover::before,
.feature-caro-btn .owl-next:hover::before,
.coach-timing > span i,
input[checked="checked"] + label,
.woocommerce .star-rating span,
.course-list .about-course .rating .fa-star,
.bread-crumb > li::before,
.res-login-page,
.theme-border-btn,
.home5-style h1,
.home5-style h2,
.home5-style h3,
.home5-style h4,
.home5-style h5,
.learning-service a:hover,
.view-gal:hover,
.heading-style-5 h2 {
	color: #ffab17;	
}
/*=========================
	BACKGROUND COLOR
=========================*/
.button,
.pkg-avatar > span,
.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus,
.prev > a, .next > a,
.event-detail,
.contact-us > ul li i,
.responsive-header .menu-btn,
.feature-caro-btn .owl-prev::after,
.feature-caro-btn .owl-next::after,
.mega2-info .mega-meta > a::before,
.edit-master .nav.nav-tabs.tab-btn > li.active,
.send-query > a:hover,
.subscriber-meta.style2 > a,
.services .button:hover,
.consult-sec form button:hover,
.package .button:hover,
.language > ul li:hover,
.branches > ul li:hover,
.searchform button,
.appoint-form .chosen-container .chosen-results li:hover,
.appoint-form .chosen-container .chosen-results li.result-selected,
.question-popup form > button,
.woocommerce #respond input#submit, 
.woocommerce a.button, 
.woocommerce button.button, 
.woocommerce input.button,
.wc-proceed-to-checkout.proceed a.button,
.place-order input.button,
.woocommerce ul.products li.product .added_to_cart,
.woocommerce div.product form.cart .button,
.del-account button,
.del-account a,
.view-more > a,
.consult-sec form input.button:hover,
.pagenation > li a.prev, 
.pagenation > li a.next,
.promo-form form input[type="submit"],
.skill-ratio > li::before,
.info > a,
.page-template-tpl-wishlist .add-cart-btn,
.sub-form button:hover,
.home-page5-footer::before {
	background: #0b5c59;
}
.progress-bar,
.place-order input.button,
.woocommerce-downloads .woocommerce-table--order-downloads tr td .woocommerce-MyAccount-downloads-file{
	background-color: #0b5c59 !important;

}
/*=========================
	BORDER COLOR
=========================*/

.couch-info > ul li a:hover,
.couch-info > ul li a.active,
.prev > a, .next > a,
.coach-timing,
.info > a,
.theme-border-btn,
.learning-service a:hover,
.view-gal:hover {
	border-color:#0b5c59;	
}
.nice-select::after {
    border-bottom-color: #0b5c59;
    border-right-color: #0b5c59;
}

.eventpage blockquote::before {
  background: #000;
  opacity : 0.6;
}.eventpage blockquote{
  color: #ffffff;
  /* background-image: url(wp-content/themes/esperto/assets/images/blockquote-bg.jpg); */
  background-repeat: no-repeat;

}.blog-single-page .single-blockquote blockquote::before, .page blockquote::before {
  background: #000000;
  opacity : 0.6;
}.blog-single-page .single-blockquote blockquote {
  /* background-image: url('wp-content/uploads/2024/01/paralax-2-1-3-1-1.jpg' )!important; */
  background-repeat: no-repeat;

}.single-meta.single-blockquote blockquote p, .page .single-meta blockquote p {
  color: #ffffff;

}#topcontrol {
    background: 062f2b none repeat scroll 0 0 !important;
    opacity: 0.5;

    color: #fff !important;

  }.language > ul li:hover, .branches > ul li:hover {
    background-color: #f8de60;

  }.language > ul li:hover, .branches > ul li:hover {
    background-color: #f8de60;

  }.main-menu > ul > li ul {
    background-color: #fff;

  }.main-menu > ul > li ul li a:hover {
    background-color: #f4f4f4;
    color: #2088c7;

  }.responsive-menu ul ul li a:hover {
    color: #2088c7;

  }.responsive-menu > ul > li.menu-item-has-children.active > a {
    border-color: #2088c7;

  }
