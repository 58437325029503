.course3-content p {
    font-size: 14px;
    margin-top: 20px;
  }
  .course3-content ul {
    margin-top: 20px;
  }
  .course3-content ul li {
    font-size: 14px;
    line-height: 25px;
  }
  .course3-author-content img {
    height: 150px;
    width: 150px;
    max-width: 320px;
    border-radius: 50%;
    text-align: center;
  }
  .course-author3-content .author-meta-content {
    text-align: center;
  }
  .author-content3-parent .course-author-name {
    display: flex;
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    margin: auto;
    width: 12%;
  }
  .course3-author-content {
    padding-top: 50px;
  }
  .author-content3-parent {
    padding-bottom: 100px;
  }
  .course3-author-content {
    text-align: center;
  }
  .author-content3-parent .author-meta-content {
    text-align: center !important;
  }
  .course3-author-content {
    background-color: #f3f3f3;
  }
  .author-content3-parent .atuhor-meta-content {
    background-color: #f3f3f3;
    margin-top: 50px;
  }
  .author-content3-parent .author-meta-content {
    background-color: #f3f3f3;
  }
  .author-content3-parent .course2-author-desc {
    padding-bottom: 40px;
    display: block;
    background-color: #f3f3f3;
    width: 50%;
    margin: auto;
  }
  .question-popup form input {
    border: 2px solid #34205e !important;
  }
  .question-popup form textarea {
    border: 2px solid #34205e !important;
  }
  .promo-form .rating-counter {
    width: 100%;
    display: flex;
    margin-bottom: 7px;
  }
  .courselisting4-meta-fields {
    display: flex;
    width: 56%;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }
  ul.course-list .about-course h4 {
    line-height: 40px;
  }
  ul.course-list .about-course h4 a {
    font-size: 28px;
  }
  .courselisting4-meta-fields span.event-type,
  .event2-type,
  .event3-type {
    font-size: 16px;
    font-weight: 500;
  }
  .courselisting4-meta-fields span i {
    padding-right: 6px;
  }
  ul.course-list li span.course-price-sale {
    font-size: 20px;
    color: #0b5c59;
  }
  ul.course-list li span.course-price-reg {
    font-size: 40px;
    color: #0b5c59 !important;
  }
  body.term-34 .gap .container {
    overflow: hidden;
  }
  .parents .profile-avatar img {
    width: 100%;
  }
  .tax-coaches_cat .parents {
    margin-top: 100px;
  }
  .tax-coaches_cat div#demo1 {
    padding-top: 17px;
  }
  .tax-coaches_cat .parents a.send_coach_mail {
    margin-top: -100px;
  }
  .page-template-tpl-course
    .course-finding
    ul.course-list
    li
    .about-course
    h4 {
    width: 100%;
  }
  /* .tax-coaches_cat .profiel-meta{
  margin-top:20px;
} */
  body.category .blog-sec .blog-avatar img {
    height: 350px;
    object-fit: cover;
    width: 310px !important;
  }
  body.category .blog-list-sec .blog-avatar a img {
    /* height: 350px;
    object-fit: cover; */
    width: 585px !important;
  }
  .promo3-meta-parent {
    transform: translate(100px, -508px);
  }
  .scoach-img-parent img {
    width: 300px !important;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
  }
  .scoach-img-parent {
    text-align: center;
  }
  .scoach-img-parent .button {
    font-size: 12px;
    left: 50%;
    padding: 6px 15px;
  }
  .coaches-cat-3 h4 {
    font-size: 40px !important;
  }
  span.coach-designation {
    color: #6a6f73;
    font-weight: 700;
  }
  .coach-tagline {
    color: #2d2f31;
    font-weight: 700;
    font-size: 15px;
    position: relative;
    top: 6px;
  }
  .coach-tagline-parent {
    width: 64%;
  }
  .coach-email {
    padding-top: 20px;
  }
  .profile-pera .about-coachtwo {
    font-size: 27px;
    font-weight: 600;
    color: #0b5c59;
  }
  .coach-skill-title {
    font-size: 27px;
    font-weight: 600;
  }
  .coach-two-students p.coach-two-students {
    font-size: 18px;
    color: #6a6f73;
    font-weight: 700;
  }
  .coach-two-students .coach-students {
    font-size: 38px;
    font-weight: 600;
  }
  .reviews-parent {
    padding-top: 20px;
  }
  .reviews-parent .coach-two-review .coach-two-students {
    font-size: 18px;
    color: #6a6f73;
    font-weight: 700;
  }
  .reviews-parent .coach-two-review .coach-reviews {
    font-size: 38px;
    font-weight: 600;
  }