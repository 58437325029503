.esperto-checkout-p .wc-block-components-checkout-step__description {
    font-size: 14px;
    line-height: 1.2;
    margin: 0 0 16px;
  }
  .esperto-checkout-p .wc-block-components-validation-error > p {
    font-size: 13px;
  }
  .esperto-checkout-p label {
    font-size: 14px !important;
  }
  .esperto-checkout-p input#email {
    font-size: 14px;
    margin-top: 34px;
    padding: 10px;
    border: 1px solid #dedede;
  }
  .esperto-checkout-p input#billing-first_name {
    font-size: 14px;
    margin-top: 40px;
    padding: 10px;
    border: 1px solid #dedede;
  }
  .esperto-checkout-p input#billing-last_name {
    font-size: 14px;
    margin-top: 40px;
    padding: 10px;
    border: 1px solid #dedede;
  }

  .esperto-checkout-p input#billing-address_1 {
    font-size: 14px;
    margin-top: 40px;
    padding: 10px;
    border: 1px solid #dedede;
  }
  .esperto-checkout-p input#billing-address_2 {
    font-size: 14px;
    margin-top: 40px;
    padding: 10px;
    border: 1px solid #dedede;
  }
  .esperto-checkout-p input#components-form-token-input-0 {
    font-size: 14px;
    margin-top: 40px;
    padding: 10px;
    border: 1px solid #dedede;
  }
  .esperto-checkout-p input#billing-city {
    font-size: 14px;
    margin-top: 37px;
    padding: 10px;
    border: 1px solid #dedede;
  }
  .esperto-checkout-p input#components-form-token-input-1 {
    font-size: 14px;
    margin-top: 37px;
    padding: 10px;
    border: 1px solid #dedede;
  }
  .esperto-checkout-p input#billing-postcode {
    font-size: 14px;
    margin-top: 37px;
    padding: 10px;
    border: 1px solid #dedede;
  }
  .esperto-checkout-p input#billing-phone {
    font-size: 14px;
    margin-top: 37px;
    padding: 10px;
    border: 1px solid #dedede;
  }

  .esperto-checkout-p
    .wc-block-checkout__terms.wp-block-woocommerce-checkout-terms-block
    span {
    font-size: 15px;
  }
  .esperto-checkout-p
    button.components-button.wc-block-components-button.wp-element-button.wc-block-components-checkout-place-order-button.contained {
    background: #0b5c59;
    color: white;
    font-size: 15px;
    border: 1px solid #0b5c59;
  }
  .esperto-checkout-p
    button.components-button.wc-block-components-button.wp-element-button.wc-block-components-checkout-place-order-button.contained:hover {
    background: transparent;
    border: 1px solid #0b5c59;
    color: #0b5c59;
    transition: 0.5s ease-in-out;
  }
  .esperto-checkout-p .wc-block-components-product-metadata__description {
    margin-top: -25px;
  }
  .esperto-checkout-p .wc-block-components-totals-coupon a {
    font-size: 17px;
  }
  .esperto-checkout-p .wc-block-components-product-metadata__description p {
    font-size: 13px !important;
  }
  .esperto-checkout-p a.wc-block-components-checkout-return-to-cart-button {
    font-size: 15px;
  }
  .esperto-checkout-p
    .components-base-control__field.css-1kyqli5.e1puf3u2
    label {
    font-size: 16px !important;
    padding-top: 10px;
  }
  div#seymour {
    width: 89%;
    margin: auto;
    margin-top: 100px;
  }
  div#contact-us-page input.wpcf7-form-control.wpcf7-text {
    height: 50px;
    font-size: 12px;
    letter-spacing: 1px;
    border: none;
    background: #f8f8f8;
  }
  div#contact-us-page
    input.wpcf7-form-control.wpcf7-submit.has-spinner.button {
    width: 220px;
    border: none;
    height: 60px;
  }
  div#contact-us-page
    .wpcf7-form-control.wpcf7-textarea.wpcf7-validates-as-required {
    border: none;
    background: #f8f8f8;
  }
  div#contact-us-page p {
    padding: 0px;
  }
  .d-flex {
    width: 50%;
    column-gap: 10px;
  }
  .d-flex .form-group {
    padding: 0px;
  }
  div#team-detail-form .contact-from input {
    background-color: #f6f6f6;
  }
  div#team-detail-form .contact-from textarea {
    background-color: #f6f6f6;
  }
  div#team-detail-form .contact-from form input {
    border: none;
    font-size: 12px;
    padding: 12px 30px !important;
  }
  div#team-detail-form .contact-from form textarea {
    border: none;
    font-size: 12px;
    padding: 5px 30px !important;
  }
  div#team-detail-form .form-group.control.form7-btn-submit {
    width: 15%;
  }
  span.elementor-progress-percentage {
    position: absolute;
    right: -35px;
    top: -34px;
  }
  .elementor-progress-bar {
    position: relative;
  }
  div#team-detail-form .contact-from .button {
    background-color: transparent !important;
    color: #000000;
    border: 1px solid #000000 !important;
    font-size: 16px !important;
  }
  .elementor-progress-bar {
    height: 20px;
    line-height: 20px;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    margin-left: 14px;
    margin-top: 7px;
    border-radius: 50px !important;
  }
  .elementor-progress-wrapper {
    height: 66%;
  }
  .elementor-progress-wrapper {
    height: 35px !important;
  }
  .elementor-progress-wrapper {
    overflow: visible !important;
    display: flex;
  }
  body.postid-844 .banner {
    display: none;
  }
  .sale-sec .prod-sale .prodct-avatar img {
    width: 100% !important;
  }
  .eventssss-date {
    display: flex;
    width: 100%;
  }
  .likes.event {
    width: 43% !important;
  }
  .event-post .eventssss-date .event-tie {
    width: 57%;
    display: flex;
  }
  .eventssss-date i {
    margin-right: 6px;
  }

  .coaches-list.style2 .coach img {
    height: 195px;
    width: 195px;
    object-position: top;
    border-radius: 50%;
    object-fit: cover;
  }
  .coaches-list.style2 .coaches2-parent {
    margin-top: 30px;
  }
  .coaches-list.style2 .coaches2-parent h3 a {
    font-size: 25px;
  }
  .coaches-list.style2 .coaches2-parent h3 {
    padding-top: 5px;
    line-height: 23px;
  }
  .coaches-list.style2 .coaches2-parent .coach-designation {
    font-size: 16px;
  }
  .coaches-list.style2 .coaches2-parent p {
    font-size: 15px;
    line-height: 25px;
  }
  .coaches-list.style2 .coaches2-parent p.coach-rating {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .coaches-list.style2 .coaches2-parent a.coach-msg {
    font-size: 15px;
    padding: 8px 12px 8px 12px;
  }
  .coaches-list.style2 .coach .coaches2-img-parent {
    margin-top: 30px;
  }
  .profiel-meta a.send_coach_mail {
    font-size: 17px;
  }
  .tax-coaches_cat ul.online-coaches {
    display: flex;
    margin-top: 50px;
  }
  .tax-coaches_cat ul.online-coaches li {
    margin-right: 30px;
  }
  .tax-coaches_cat .coach-online-profile ul.online-coaches li {
    transition: 0.3s ease-in-out;
  }
  .tax-coaches_cat .coach-online-profile ul.nav li a {
    color: #222222;
    font-size: 15px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: var(--e-global-color-accent);
    padding: 11px 30px 11px 30px;
    border-radius: 50px 50px 50px 50px;
  }
  .tax-coaches_cat .coach-online-profile ul.nav li a.active {
    color: #ffffff;
    background-color: var(--e-global-color-primary);
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: var(--e-global-color-primary);
    padding: 11px 30px 11px 30px;
    border-radius: 50px 50px 50px 50px;
  }
  .question-popup #coachfom button {
    color: white;
  }
  .course-promo-style3 h2.course3-title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
  }
  .about-course3 {
    font-size: 15px;
    color: #161616;
    font-weight: 500;
  }
  span.course3-languages {
    background-color: #f2f2f2;
    font-size: 15px;
    padding: 20px 25px 20px 25px;
    border-radius: 10px;
    display: block;
    position: relative;
    top: 25px;
  }
  .promo-video3 {
    margin-top: 50px;
  }
  .promo-video3 img {
    width: 800px;
    height: 430px;
    object-fit: cover;
    border-radius: 20px;
  }
  .promo-video3 a {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    left: 50%;
    position: absolute;
    top: 60%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-transform: capitalize;
    display: grid;
    text-align: center;
  }
  .promo-video3 a i {
    display: inline-block;
    font-size: 50px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .promo3-meta-parent {
    transform: translate(140px, -508px);
  }
  .promo3-package ul li {
    list-style: none;
    font-size: 14px;
    padding-top: 12px;
    font-weight: 400;
  }
  .promo3-package ul {
    padding: 0px;
  }
  .promo3-package {
    margin-top: 30px;
  }
  .course-promo-style3 {
    margin-top: 120px;
  }
  .promo3-package ul li i {
    padding-right: 8px;
  }
  .promo3-meta-parent svg {
    display: none;
  }
  .promo3-meta-parent .event3-date {
    background-color: #f2f2f2;
    padding: 20px 25px 10px 25px;
    border-radius: 20px;
  }
  .event3-date .video-prices {
    color: #000000;
  }
  form.enroll-form {
    margin-top: 30px;
  }
  .enroll-form input[type="submit"] {
    border: none;
    padding: 13px 20px 13px 20px;
    border-radius: 13px;
  }
  .enroll-form input[type="submit"] {
    background-color: #062f2b;
    color: white;
  }